var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"showSearch":false,"showBreadcrumbs":true,"errorMessage":_vm.error,"topActionButtonPrimary":{}},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[(_vm.didInit)?_c('div',[(!_vm.resourceNotFound)?_c('div',[_c('custom-alert',{attrs:{"show":true,"dismissible":false,"type":"warning","title":"Legacy Quote"}},[_c('p',{staticClass:"font-bold"},[_vm._v(" This quote cannot be modified. ")])]),(
          _vm.editing &&
            _vm.editing.status &&
            (_vm.editing.status === 'submitted' ||
              _vm.editing.status === 'received') &&
            _vm.policyNumber
        )?_c('custom-alert',{attrs:{"title":"Quote Bound","show":true,"dismissible":false,"type":"success"}},[_c('p',{staticClass:"font-bold"},[_vm._v(" Your Quote Number is "),_c('span',[_vm._v(_vm._s(_vm.policyNumber))])]),_c('p',[_vm._v("Your application has been "+_vm._s(_vm.editing.status)+" and bound.")])]):_vm._e(),_c('h1',{staticClass:"text-xl font-bold"},[_vm._v("Property Rating")]),(_vm.editing.companyNumbers.length > 0)?_c('section',[_c('dt',{staticClass:"text-sm text-gray-500 font-medium mb-3 mt-10"},[_vm._v(" Selected Company ")]),_c('dd',{staticClass:"mt-1 mb-5 text-sm text-gray-900"},[_vm._v(" "+_vm._s(_vm.getCompany(_vm.editing))+" ")]),_c('dt',{staticClass:"text-sm text-gray-500 font-medium mb-3 mt-10"},[_vm._v(" Effective Date ")]),_c('dd',{staticClass:"mt-1 mb-5 text-sm text-gray-900"},[_vm._v(" "+_vm._s(_vm.getFormattedDate())+" ")]),_c('dt',{staticClass:"text-sm text-gray-500 font-medium mb-3 mt-10"},[_vm._v(" Policy Type ")]),_c('dd',{staticClass:"mt-1 mb-5 text-sm text-gray-900"},[_vm._v(" "+_vm._s(_vm.getPolicyType)+" ")])]):_vm._e(),_c('DisplayData',{attrs:{"data":_vm.getData}}),(_vm.endorsements && Object.keys(_vm.endorsements).length > 0)?_c('section',{staticClass:"border-t mt-5 pt-5"},[_c('h1',{staticClass:"text-xl font-bold mb-3"},[_vm._v("Endorsements")]),_vm._l((Object.keys(_vm.endorsements)),function(key,index){return _c('dl',{key:index,staticClass:"pt-3 mb-3"},[_c('dd',{staticClass:"mt-1 text-sm text-gray-900"},[_vm._v(" "+_vm._s(_vm.endorsements && _vm.endorsements[key] && _vm.endorsements[key][0] && _vm.endorsements[key][0].m_fmName ? _vm.endorsements[key][0].m_fmName.toString() : "")+" ")])])})],2):_vm._e(),(_vm.editing)?_c('section',{staticClass:"border-t pt-5 mt-5"},[_c('h1',{staticClass:"text-xl font-bold"},[_vm._v("Applicant's Details")]),_c('DisplayData',{attrs:{"data":_vm.getApplicantsData}})],1):_vm._e(),(
          _vm.editing &&
            _vm.editing.quoteApplication &&
            _vm.editing.quoteApplication.coApplicants &&
            _vm.editing.quoteApplication.coApplicants.length > 0
        )?_c('section',{staticClass:"border-t mt-5 pt-5"},[_c('h3',{staticClass:"font-medium"},[_vm._v("Co Applicants")]),_c('DisplayLoopingData',{attrs:{"data":_vm.getCoApplicantData}})],1):_vm._e(),(
          _vm.editing.mortgages ||
            _vm.editing.loadNumber ||
            _vm.editing.priorCoverage ||
            _vm.editing.remarks
        )?_c('section',{staticClass:"mt-5 pt-5"},[(_vm.editing.mortgages && _vm.editing.mortgages.length > 0)?_c('h1',{staticClass:"text-xl font-bold mb-5"},[_vm._v(" Mortgages and Remarks ")]):_vm._e(),(_vm.editing.mortgages && _vm.editing.mortgages.length > 0)?_c('DisplayLoopingData',{attrs:{"data":_vm.getMortgagesData}}):_vm._e(),_c('div',{staticClass:"grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 border-gray-200 py-4 border-t"},[(_vm.editing.clientEmail)?_c('dl',{staticClass:"sm:col-span-4"},[_c('dt',{staticClass:"font-medium text-lg"},[_vm._v(" Insured Email ")]),_c('dd',{staticClass:"mt-1 text-md text-gray-900"},[_vm._v(" "+_vm._s(_vm.editing.clientEmail ? _vm.editing.clientEmail : "N/A")+" ")])]):_vm._e(),(_vm.editing.createdByData && _vm.editing.createdByData.displayName)?_c('dl',{staticClass:"sm:col-span-4"},[_c('dt',{staticClass:"font-medium text-lg"},[_vm._v(" Name of Agency Employee Who Created Application ")]),_c('dd',{staticClass:"mt-1 text-md text-gray-900"},[_vm._v(" "+_vm._s(_vm.editing.createdByData ? _vm.editing.createdByData.displayName : "N/A")+" ")])]):_vm._e()])],1):_vm._e()],1):_c('ResourceNotFound',{attrs:{"title":_vm.resourceNotFoundMsg,"actionButtonTitle":"Back to quotes","actionRedirectPath":"/quotes"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }